"use strict";
//defer video load
function init() {
var vidDefer = document.getElementsByTagName('iframe');
for (var i=0; i<vidDefer.length; i++) {
if(vidDefer[i].getAttribute('data-src')) {
vidDefer[i].setAttribute('src',vidDefer[i].getAttribute('data-src'));
} }
var imgDefer = document.getElementsByTagName('img');
for (var j=0; j<imgDefer.length; j++) {
if(imgDefer[j].getAttribute('data-src')) {
imgDefer[j].setAttribute('src',imgDefer[j].getAttribute('data-src'));
} }
}
window.onload = init;